import { storage } from 'utils/storage'

type GCP_DNS = 'GCP'
type TCC_DNS = 'TCC'

export type DNS = GCP_DNS | TCC_DNS

const DEFAULT_DNS = 'GCP'

export const getDNS = () => storage.get('_dns') || DEFAULT_DNS

export const setDNS = (dns: DNS) => {
  storage.set('_dns', dns)
}
