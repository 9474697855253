import i18n from 'i18n'
import { enUS, idID, LocaleCode } from './constant/country'

const mapLangToSupportedLocale: Record<string, LocaleCode> = {
  id: idID,
  'en-US': enUS,
  'id-ID': idID
}

// https://asphalt.pages.golabs.io/asphalt-react/docs/components/date-picker#locale
const mapLangToSupportedDatePickerProps: Record<string, string> = {
  id: 'id',
  'en-US': 'en-US',
  'id-ID': 'id'
}

export function getLocale(): LocaleCode {
  return mapLangToSupportedLocale[localStorage.locale] || enUS
}

export function mapLocaleToDatePickerProps() {
  return mapLangToSupportedDatePickerProps[getLocale()]
}

export const setLocale = (locale: LocaleCode) => i18n.changeLanguage(locale)
