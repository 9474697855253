/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import 'twin.macro'
import ToggleSwitch from '@asphalt-react/toggle-switch'

import config from 'configuration'
import { DNS, getDNS, setDNS } from './set-dns'

const containerStyle = css`
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  border: 1px solid #e0e0e0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const ToggleSwitchDNS = () => {
  const [state, setState] = useState(getDNS() === 'TCC')

  const handleToggle = () => {
    const dns = state ? 'GCP' : 'TCC'
    setState((prev) => !prev)
    setDNS(dns as DNS)
    window.location.reload()
  }

  if (config.ENV === 'production') return null

  return (
    <div tw="fixed w-auto p-2 bg-white bottom-0" css={containerStyle}>
      <div tw="flex items-center">
        <span>GCP</span>
        <span tw="mx-4">
          <ToggleSwitch
            data-testid="tggl-switch-dns"
            on={state}
            onToggle={handleToggle}
          />
        </span>
        <span>TCC</span>
      </div>
    </div>
  )
}

export default ToggleSwitchDNS
