import * as React from 'react'

import ToggleSwitchDNS from 'components/ToggleSwitchDNS'

const ScreenCenterLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        background: 'white'
      }}
    >
      <ToggleSwitchDNS />
      {children}
    </div>
  )
}

export default ScreenCenterLayout
