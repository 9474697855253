export default {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000',
  API_BASE_URL_TCC:
    process.env.REACT_APP_API_BASE_URL_TCC || 'http://localhost:4000',
  ENV: process.env.REACT_APP_ENV || 'development',
  GMAPS_API_KEY: process.env.REACT_APP_GMAPS_API_KEY || 'secret',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || 'http://something',
  SENTRY_TRACESSAMPLERATE: +(
    process.env.REACT_APP_SENTRY_TRACESSAMPLERATE || 0.1
  ),
  EMAIL_SUPPORT: JSON.parse(
    process.env.EMAIL_SUPPORT ||
      '{"ID":"support-gocorp@gojek.com","SG":"gocorpsupport.sg@gojek.com","VN":"gocorpsupport.vn@gojek.com"}'
  ),
  // feature toggle: default = true
  SFTP_HISTORY_DOWNLOAD_ENABLED:
    process.env.REACT_APP_SFTP_HISTORY_DOWNLOAD_ENABLED === undefined ||
    process.env.REACT_APP_SFTP_HISTORY_DOWNLOAD_ENABLED === 'true',
  // set timestamp from https://currentmillis.com
  WHATSNEW_EXPIRED_AT: +(
    process.env.REACT_APP_WHATSNEW_EXPIRED_AT || 1622480400000
  ),
  WHATSNEW_CREATED_AT: +(
    process.env.REACT_APP_WHATSNEW_CREATED_AT || 1590944400000
  ),
  WHATSNEW_COUNTRY_CODE:
    process.env.REACT_APP_WHATSNEW_COUNTRY_CODE || 'ID,SG,VN',
  WHATSNEW_COMPANY_BILLING_TYPE:
    process.env.REACT_APP_WHATSNEW_COMPANY_BILLING_TYPE || 'CC,MONTHLY_BILLING',
  HOMEBANNER_CAMPAIGN_EXPIRED_AT: +(
    process.env.REACT_APP_HOMEBANNER_CAMPAIGN_EXPIRED_AT || 1622480400000
  ),
  HOMEBANNER_CAMPAIGN_CREATED_AT: +(
    process.env.REACT_APP_HOMEBANNER_CAMPAIGN_CREATED_AT || 1590944400000
  ),
  HOMEBANNER_CAMPAIGN_COUNTRY_CODE:
    process.env.REACT_APP_HOMEBANNER_CAMPAIGN_COUNTRY_CODE || 'ID,SG',
  CC_COMINGSOON_EXPIRED_AT: +(
    process.env.REACT_APP_CC_COMINGSOON_EXPIRED_AT || 1590944400000
  ),
  MAX_TIME_WINDOW_RULES: +(process.env.REACT_APP_MAX_TIME_WINDOW_RULES || 9),
  MAX_POI_RULES: +(process.env.REACT_APP_MAX_POI_RULES || 10),
  MAX_POI_FOOD_RULES: +(process.env.REACT_APP_MAX_POI_FOOD_RULES || 10),
  MAX_TRANSPORT_VOUCHER_TIME_WINDOW_RULES: +(
    process.env.REACT_APP_MAX_TRANSPORT_VOUCHER_TIME_WINDOW_RULES || 5
  ),
  MAX_TRANSPORT_VOUCHER_POI_RULES: +(
    process.env.REACT_APP_MAX_TRANSPORT_VOUCHER_POI_RULES || 5
  ),
  MAX_FOOD_VOUCHER_POI_RULES: +(
    process.env.REACT_APP_MAX_FOOD_VOUCHER_POI_RULES || 5
  ),
  S2_CELL_ID_LEVEL: +(process.env.REACT_APP_S2_CELL_ID_LEVEL || 13),
  MAX_PAYMENT_METHOD_LABEL_LENGTH: +(
    process.env.REACT_APP_MAX_PAYMENT_METHOD_LABEL_LENGTH || 30
  ),
  MIN_PAYMENT_METHOD_LABEL_LENGTH: +(
    process.env.REACT_APP_MIN_PAYMENT_METHOD_LABEL_LENGTH || 3
  ),
  FOOD_RULE_MAX_DISTANCE_IN_KM: +(
    process.env.REACT_APP_FOOD_RULE_MAX_DISTANCE_IN_KM || 45
  ),
  FOOD_VOUCHER_KV: JSON.parse(
    process.env.REACT_APP_FOOD_VOUCHER_KV ||
      '{"ID":"https://i.gojekapi.com/darkroom/gopoints-id/v2/images/public/vbimages/VnzeZgLKp4p0yFLlSGkZ7Ak3aFmKF6F4zqMhqqFeJKk.jpg","SG":"https://i.gojekapi.com/darkroom/gopoints-id/v2/images/public/vbimages/VnzeZgLKp4p0yFLlSGkZ7Ak3aFmKF6F4zqMhqqFeJKk.jpg","VN":"https://i.gojekapi.com/darkroom/gopoints-id/v2/images/public/vbimages/VnzeZgLKp4p0yFLlSGkZ7Ak3aFmKF6F4zqMhqqFeJKk.jpg"}'
  ),
  VOUCHER_KV: JSON.parse(
    process.env.REACT_APP_VOUCHER_KV ||
      '{"ID":"https://i.gojekapi.com/darkroom/gopoints-id/v2/images/public/vbimages/58s0WOSUkkFLJAeI7REZI1v8LO982GLh8957P4PoFZ0.jpg","SG":"https://i.gojekapi.com/darkroom/gopoints-id/v2/images/public/vbimages/58s0WOSUkkFLJAeI7REZI1v8LO982GLh8957P4PoFZ0.jpg","VN":"https://i.gojekapi.com/darkroom/gopoints-id/v2/images/public/vbimages/58s0WOSUkkFLJAeI7REZI1v8LO982GLh8957P4PoFZ0.jpg"}'
  ),
  MAX_MONETARY_VALUE_VOUCHER: JSON.parse(
    process.env.REACT_APP_MAX_MONETARY_VALUE_VOUCHER ||
      '{"ID":350000,"SG":18000,"VN":500000}'
  ),
  MAX_MONETARY_VALUE_FOOD_VOUCHER: JSON.parse(
    process.env.REACT_APP_MAX_MONETARY_VALUE_FOOD_VOUCHER ||
      '{"ID":350000,"SG":18000,"VN":500000}'
  ),
  // feature toggle: default = true
  EMPLOYEE_ACTIVATION_FEATURE:
    process.env.REACT_APP_EMPLOYEE_ACTIVATION_FEATURE === undefined ||
    process.env.REACT_APP_EMPLOYEE_ACTIVATION_FEATURE === 'true',
  // feature toggle: default = false
  BLOCK_AMEX_CC_FEATURE:
    process.env.REACT_APP_BLOCK_AMEX_CC_FEATURE !== undefined &&
    process.env.REACT_APP_BLOCK_AMEX_CC_FEATURE === 'true',
  // feature toggle: default = ID
  RBAC_ENABLED_COUNTRY_CODE:
    process.env.REACT_APP_RBAC_ENABLED_COUNTRY_CODE || 'ID',
  // feature toggle: default = ID,VN
  FOOD_RULE_ENABLED_COUNTRY_CODE:
    process.env.REACT_APP_FOOD_RULE_ENABLED_COUNTRY_CODE || 'ID,VN',
  // feature toggle: default = ID
  LOGISTIC_LIMIT_ENABLED_COUNTRY_CODE:
    process.env.REACT_APP_LOGISTIC_LIMIT_ENABLED_COUNTRY_CODE || 'ID',
  // feature toggle: default = ID
  FOOD_LIMIT_ENABLED_COUNTRY_CODE:
    process.env.REACT_APP_FOOD_LIMIT_ENABLED_COUNTRY_CODE || 'ID',
  // feature toggle alpha: default = ''
  FOOD_ENABLED_COMPANY_IDS_ALPHA:
    process.env.REACT_APP_FOOD_ENABLED_COMPANY_IDS_ALPHA || '',
  // feature toggle: default = ''
  FOOD_ENABLED_COUNTRIES_ALPHA:
    process.env.REACT_APP_FOOD_ENABLED_COUNTRIES_ALPHA || '',
  // feature toggle alpha: default = ''
  TRANSPORT_ENABLED_COMPANY_IDS_ALPHA:
    process.env.REACT_APP_TRANSPORT_ENABLED_COMPANY_IDS_ALPHA || '',
  // feature toggle: default = ''
  TRANSPORT_ENABLED_COUNTRIES_ALPHA:
    process.env.REACT_APP_TRANSPORT_ENABLED_COUNTRIES_ALPHA || '',
  // feature toggle: default = ID,SG
  CC_ENABLED_COUNTRY_CODE:
    process.env.REACT_APP_CC_ENABLED_COUNTRY_CODE || 'ID,SG',
  // feature toggle: default = SG
  CONCIERGE_WEB_ENABLED_COUNTRY_CODE:
    process.env.REACT_APP_CONCIERGE_WEB_ENABLED_COUNTRY_CODE || 'SG',
  CONCIERGE_WEB_URL: process.env.REACT_APP_CONCIERGE_WEB_URL || '#',
  CONCIERGE_WEB_HELP_ARTICLE_URL:
    process.env.REACT_APP_CONCIERGE_WEB_HELP_ARTICLE_URL || '#',
  // feature toggle: default = 19
  VOUCHER_CODE_SERVICE_TYPE_ID_DISABLED:
    process.env.REACT_APP_VOUCHER_CODE_SERVICE_TYPE_ID_DISABLED || '19,85',
  // feature toggle: default = ID,SG
  VOUCHER_CODE_ENABLED_COUNTRY_CODE:
    process.env.REACT_APP_VOUCHER_CODE_ENABLED_COUNTRY_CODE || 'ID,SG',
  // feature toggle: default = false
  VOUCHER_CODE_CC_FEATURE:
    process.env.REACT_APP_VOUCHER_CODE_CC_FEATURE !== undefined &&
    process.env.REACT_APP_VOUCHER_CODE_CC_FEATURE === 'true',
  // feature toggle: default = false
  VOUCHER_CODE_PREPAID_FEATURE:
    process.env.REACT_APP_VOUCHER_CODE_PREPAID_FEATURE !== undefined &&
    process.env.REACT_APP_VOUCHER_CODE_PREPAID_FEATURE === 'true',
  MAX_VALID_DAY_VOUCHER_CREATION: +(
    process.env.REACT_APP_MAX_VALID_DAY_VOUCHER_CREATION || 7
  ),
  GOJEK_DYNAMIC_LINK:
    process.env.REACT_APP_GOJEK_DYNAMIC_LINK || 'http://something',
  GOJEK_BUSINESS_PROFILE_UNIVERSAL_LINK:
    process.env.REACT_APP_GOJEK_BUSINESS_PROFILE_UNIVERSAL_LINK ||
    'http://something',
  MIDTRANS_ENV: process.env.REACT_APP_MIDTRANS_ENV || 'sandbox',
  MIDTRANS_ID_CLIENT_KEY:
    process.env.REACT_APP_MIDTRANS_ID_CLIENT_KEY || 'secret',
  MIDTRANS_SG_CLIENT_KEY:
    process.env.REACT_APP_MIDTRANS_SG_CLIENT_KEY || 'secret',
  MIDTRANS_VN_CLIENT_KEY:
    process.env.REACT_APP_MIDTRANS_VN_CLIENT_KEY || 'secret'
}
